import React from 'react';
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../Components/Layout';
import './index.scss';

const IndexPage = ({data}) => {
  const { title, description } = data.site.siteMetadata
  
  return (
    <Layout>
      <section className="home-view">
        <div className="portrait-container">
          <div className="portrait">
            <StaticImage src='../images/portrait-03.png' alt="A picture of Matt Umland" placeholder="blurred"/>
          </div>
        </div>
        <section className="bio-container">
          <div className="bio-header">
            <h1 className="title">{title}</h1>
          </div>
          <div className="bio">
            <h2>{description}</h2>
          </div>
          <form name="contact" method="POST" data-netlify="true">
            <h3>Say hi</h3>
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>Name <input type="text" name="name" /></label>
            </p>
            <p>
              <label>Email <input type="email" name="email" /></label>
            </p>
            <p>
              <label>Message <textarea name="message"></textarea></label>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </section>
      </section>
    </Layout>
  );
}

export default IndexPage;

export const data = graphql` {
  site {
    siteMetadata {
      title
      description
    }
  }
}`
